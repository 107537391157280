import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  Title,
  TextBlock,
  Button,
} from '@lxlabs/change-ad-components';
import Graphic from './graphic';

const Style = styled(Card)`
  & > div {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xlarge);

    @media only screen and (max-width: 1060px) and (min-width: 834px)  {
      flex-direction: column;
    }

    @media only screen and (max-width: 561px)  {
      flex-direction: column;
    }
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    margin-top: auto;
    margin-right: auto;
  }
`;

const SideImage = styled(Graphic)`
  margin-top: calc(var(--spacing-small) * -1);

  @media screen and (max-width: 834px) {
    margin-top: var(--spacing-medium);
  }
`;

const SectionCard = ({ title, text, link }) => (
  <Style alignment="left">
    <SideImage name={title} />
    <Inner>
      <Title>{title}</Title>
      <TextBlock spacing={{ top: 'medium', bottom: 'medium' }}>
        {text}
      </TextBlock>
      <Button text="Learn more" href={link} />
    </Inner>
  </Style>
);

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

SectionCard.defaultProps = {

};

export default SectionCard;
